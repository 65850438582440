import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import styles from "./index.module.scss"

class SiteIndex extends React.Component {

  componentDidMount() {
    const lang = this.judgeLang()
    setTimeout(() => {
      window.location.replace(`/${lang}/`)
    }, 0);
  }

  judgeLang = () => {
    const lang = window.navigator.language
    console.log(lang)
    if (lang && lang.startsWith('ja')) {
      return 'ja'
    }
    return 'en'
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <div className={styles.main}>
        <SEO title={siteTitle} />
        {/* <div className={`${styles.excerpt} ${styles[this.state.langStyle]}`}>
          <div className={styles.text}>{this.state.excerpt}</div>
          <div className={styles.clip}></div>
        </div> */}
      </div>
    )
  }
}

export default SiteIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {category: {eq: "notation"}}, html: {ne: ""}, excerpt: {ne: " �"}}, sort: {fields: [frontmatter___id], order: ASC}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
